import { observable, action, toJS } from "mobx";
import axios from 'axios';
// import io from 'socket.io-client';
import { api } from '../Configs/api';
// import { ButtonGroup } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('he')

// const socket = io(api.my_shop_api.socketUrl);
export const my_env = api.my_shop_api;

// if(my_env.debug){
//     console.log("my_env:", my_env);
// }

class MyShopApiStore {
    my_env = api.my_shop_api;
    bgCodeDef = 'bg_2';
    mainColorDef = 'grey';
    // mStatus = my_env.mStatus? my_env.mStatus : false;
    // MaintText = my_env.mText? my_env.mText : '';

    // @observable isPastadioroSite = false;
    @observable stepNum = null;
    @observable cartPrice = 0;
    // @observable cartItemList = [];
    @observable cartList = [];
    @observable isEditCartPackForm = false;
    @observable isCartItemUpdating = false;
    @observable cartItemUpdating = null;
    @observable isPackItemUpdating = false;
    @observable packItemUpdating = null;
    @observable isOpenForEdit = false; //cart item -> init open or edit open !
    
    @observable csrf = null;
    @observable isMaintenance = false; //flag to show "server in maintenance" in case of bad responce
    @observable apiResp = null; //responce from set-shop
    @observable shopData = null; //data from apiResp
    @observable shop = null;    //shop from shopData
    @observable shopLatLng = null;    //shop coords
    @observable deliveryLatLng = null;    //user coords
    @observable deliveryCost = 0;    //shop coords
    @observable categories = null;    //categories from shop
    @observable itemListResp = null;    //resp for items from category with ActiveSideMenuIdx
    @observable itemList = null;    //itemListResp.categoryProducts[cat]
    @observable shopsData = null;    // get-shops resp data

    @observable history = null; //local? history of previous purchases

    @observable init = null;
    @observable ActiveSideMenuIdx = 0;
    @observable MaxSideMenuIdx = 0;
    @observable bgCode = this.bgCodeDef;
    @observable fileBg = '';
    @observable mainColor = null;
    @observable selectedCategoryIdx = 0;

    @observable categoryPage = 1;
    @observable categoryPageCount = 1;

    @observable isProductsLoading = false;

    @observable cardNumber = '';
    @observable otherPaymentType = '';

    @observable userParams = {
        name: '',
        phone: '',
        streetName: '',
        streetNumber: '',
        floorNumber: '',
        entranceCode: '',
        apartmentNumber: '',
        city: '',
        email: ''
    };

    @observable creditParams = {
        token: '',
        id: '',
        cc_number: '',
        cvv: '',
        month: '',
        year: '',
        track2: ''
    };

    @action setCardNumber = (cardNumber) => {
        this.cardNumber = cardNumber;
    }
    @action setOtherPaymentType = (paymentType) => {
        this.otherPaymentType = paymentType;
    }

    @action 
    resetCreditParams = () => {
        this.creditParams = {
            token: '',
            id: '',
            cc_number: '',
            cvv: '',
            month: '',
            year: '',
            track2: ''
        }
    }

    @observable userToken = null;
    @observable userId = null;
    @observable shopId = null;
    @observable shopName = null;

    @observable filteredTags = [];
    @observable filteredShops = [];
    @observable hasFilters = true;
    @observable storeOpened = false;
    // @observable isValidSMS= false;
    @observable isWrongSMSCode = false;

    @observable orderData = null;
    @observable couponDiscount = 0;
    @observable coupons = [];
    @observable hasPostCoupon = false;
    @observable postCouponData = [];
    
    @observable suggestions = [];
    @observable searchValue = '';
    @observable searchFieldIsOpen = false;
    @observable isSearchingProducts = false;
    @observable basketHistory = null; //item-list
    @observable ordersHistory = [];
    @observable historyPage = 1;

    @observable sessionUrl = '';
    @observable creditUniqueId = '';
    
    @observable subcatItemList = null;

    @action
    setSubcatItemList = (itemList) => {
        this.subcatItemList = itemList;
    }

    @action
    setCreditUniqueId = (creditUniqueId) => {
        this.creditUniqueId = creditUniqueId;
    }
    
    @action
    setHistoryPage = () => {
        this.historyPage = 1;
    }

    @action
    setSessionUrl = (url) => {
        this.sessionUrl = url;
    }
    
    @action
    setOrdersHistory = (orders) => {
        this.ordersHistory = [...this.ordersHistory, ...orders];
    }

    @action
    resetOrdersHistory = () => {
        this.ordersHistory = [];
    }

    @action
    resetHistoryPage = () => {
        this.historyPage = 1;
    }

    @action
    setBasketHistory = (itemList) => {
        this.basketHistory = itemList;
    }

    @action
    setSuggestions = (itemList) => {
        this.suggestions = itemList;
    }

    @action
    setSearchValue = (text, hide = false) => {
        this.searchValue = text;
        if(hide) this.toggleSearchField(false);

        //console.log('Search hider', text, hide);

        return true;
    }

    @action
    toggleSearchField = (value) => {
        this.searchFieldIsOpen = value;
    }

    // @observable version = 'v1.0.02342222';

    // @action
    // clearLocalStorage = () => {
    //     console.log("clearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorage")
    //     if(this.version !== 'v1.0.0'){
    //         console.log("clearLocalStorageclearLocalStorageclearLocalStoragec22222222222222222222222222222222learLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorage")
    //         this.version = 'v1.0.0'
    //         localStorage.removeItem('__mobx_sync__');
    //     }
    // }
    
    @action
    setDeliveryCost = (deliveryCost) => {
        this.deliveryCost = deliveryCost;
        this.calcTotalCartPrice();
    }

    @action
    setCoupon = (code, discount) => {
        const inCoupons = this.coupons.some(coupon => coupon.id === this.shopId);
        
        if(inCoupons){
            this.coupons.forEach(coupon => {
                if((coupon.id === this.shopId) && this.shopId){
                    coupon.code = code;
                    coupon.discount = discount;
                }
            })
        }
        else{
            this.coupons.push({code, discount, id: this.shopId});
        }
    }

    @action
    getCoupon = () => {
        for(let i=0 ; i<this.coupons.length ; i++){
            if((this.shopId === this.coupons[i].id) && this.shopId){
                return this.coupons[i];
            }
        }

        return null;
    }

    @action
    clearCoupon = () => {
        const hasCoupon = this.coupons.some(coupon => coupon.id === this.shopId);
        if(hasCoupon){
            let newCoupons = [];
            this.coupons.forEach(coupon => {
                if(coupon.id !== this.shopId){
                    newCoupons.push({...coupon});
                }
            })
            this.coupons = newCoupons;
        } 
    }

    @action
    setWrongSMSCode = flag => {
        this.isWrongSMSCode = flag;
    }

    @action
    resetCartData = () => {
        this.stepNum = null;
        this.cartPrice = 0;
        this.isEditCartPackForm = false;
        this.isCartItemUpdating = false;
        this.cartItemUpdating = null;
        this.isPackItemUpdating = false;
        this.packItemUpdating = null;
    }

    @action
    clearReadyToOrderCartItems = () => {
        for(let i=this.getCart().cartItemList.length-1 ; i>=0 ; i--){
            if(this.getCart().cartItemList[i].isReadyToOrder){
                this.removeCartItem(i);
            }
        }

    }

    @action
    setCart = () => {
        const isCartInList = this.cartList.some(cart => cart.shopId === this.shopId);
        if(!isCartInList){
            this.cartList.push({cartItemList: [], shopId: this.shopId});
        }
        this.calcTotalCartPrice();
    }

    @action
    getCart = () => {
        for(let i=0 ; i<this.cartList.length ; i++){
            if(this.cartList[i].shopId === this.shopId){
                return this.cartList[i];
            }
        }
        return null;
    }

    // @action
    // setValidSMS = (bool) => {
    //     this.isValidSMS = bool;
    // }

    @action
    openStore = (shopId, shopName) => {
        // const { setShopId,setShopName, getApiResp_SetShop, getApiResp, setInit } = this.props.myShopApiStore;
        this.setShopId(shopId);
        this.setShopName(shopName);
        // await getApiResp_SetShop();
        this.setInit();
        this.setCart();
        this.storeOpened = true;
        // window.location.pathname = `/${shopId}`
    }

    @action
    closeStore = () => {
        console.log('closing store...')
        this.storeOpened = false;
        this.resetCartData();
        /////
        this.resetState();
        this.shopLatLng = null;
        this.deliveryLatLng = null;
        this.deliveryCost = 0;  
        this.csrf = null;
        this.isMaintenance = false;
        this.apiResp = null;
        this.shopData = null;
        this.shop = null;
        this.categories = null;
        this.itemListResp = null;
        this.itemList = null;

        this.suggestions = [];
        this.searchValue = '';
        this.isSearchingProducts = false;

        this.ordersHistory = [];
        this.basketHistory = null;
        this.historyPage = 1;
        
        /////

    }

    @action
    setFilteredShops = (shops) => {
        if(!shops.length && this.filteredTags.length){ //checks if filter not success (Stores Not Found!)
            this.hasFilters = false;
        }
        this.filteredShops = shops;
    }

    @action
    addFilteredTag = (tag) => {
        const tagInArr = this.filteredTags.some(t => t.id === tag.id);
        if(tagInArr){
            this.filteredTags = this.filteredTags.filter(t => t.id !== tag.id);
        }
        else{
            this.filteredTags.push(tag)
        }
    }

    @action
    cancleFiltereds = () => {
        this.hasFilters = true;
        if(this.filteredTags.length){
            this.filteredTags = [];
        }
        if(this.filteredShops.length){
            this.filteredShops = [];
        }
    }

    // @action
    // setShopResp = async () => {
    //     console.log("this.shopData(1):",this.shopData)
    //     const { shopId } = this.my_env;
    //     let paymentEndpoint = null;
    //     let paymentKey = null;
    //     let directPayment = null;

    //     var form = new FormData();
    //     form.append('shopId', shopId);
    //     const url = this.getApi('set-shop');
    //     // const url = "http://myshop.bigapps.eu.ngrok.io/api/set-shop/"
    //     await axios.post(url, form, null)
    //     .then(response => {
    //         console.log("response:",response) 
    //         console.log("my_env:", my_env) 
    //         paymentEndpoint = response.data.data.shop.paymentEndpoint;
    //         paymentKey = response.data.data.shop.paymentKey;
    //         directPayment = response.data.data.shop.directPayment;
    //     })
    //     .catch(err => {
    //         console.log("err>>>", err)
    //     })

    //     console.log("this.shopData(2):",this.shopData)
    //     const res = { paymentEndpoint, paymentKey, directPayment };
    //     return res;
    // }

    @action
    setOrderData = (key, value) => {
        this.orderData[key] = value;
    }
    
    @action
    resetOrderData = () => {
        const { orderTypes } = this.shop;
        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;

        this.orderData = {
            distance: 0,
            isCredit: true,
            isDelivery: hasDeliveryOp,
            branch: '',
            deliveryData: {
                date: '',
                from: '',
                to: '',
                orderComment: '',
                deliveryGuyComment: ''
            },
            pickupData: {
                date: '',
                from: '',
                to: '',
                orderComment: '',
            }
        };
    }

    @action
    getBranchId = () => {
        let branchId = null;
        this.shop.branches &&
            this.shop.branches.forEach(branch => {
                if(branch.name === this.orderData.branch){
                    branchId = branch.id;
                }
            })


        return branchId;
    }

    @action
    getDeliveryCostOfBranch = () => {
        let deliveryCost = 0;
        this.shop.branches &&
            this.shop.branches.forEach(branch => {
                if(branch.name === this.orderData.branch){
                    deliveryCost = branch.deliveryCost;
                }
            })


        return deliveryCost;
    }


    @action
    setShopId = (shopId) => {
        this.shopId = shopId;
    }
  
    @action
    setShopName = (shopName) => {
        this.shopName = shopName;
    }

    @action
    validateCardResp = async (TerminalNumber, Password, Track2, CardNumber, ExpDate_MMYY) => {
        const data = {
            TerminalNumber,
            Password,
            Track2,
            CardNumber,
            ExpDate_MMYY
        }

        const headers = { "Content-Type": 'application/json; charset=utf-8' };

        let token = null;
        let errorMsg = '';
        const url = "https://pci.zcredit.co.il/ZCreditWS/api/Transaction/ValidateCard";
        
        await axios.post(url, data, { headers })
        .then(response => {
            // console.log("validateCardResp RESP::", response)
            token = response.data.Token;
            errorMsg = response.data.ReturnMessage;
        })
        .catch(err => {
            // console.log("validateCardResp ERROR::", err);
            errorMsg = 'ERROR';
        })

        // console.log("validateCardResp ERROR_MSG::",errorMsg)
        return {token, errorMsg};
    }

    @action
    commitFullTransResp = async (token, TerminalNumber, Password, Track2, HolderID, CardNumber, CVV, ExpDate_MMYY) => {
        const deliveryPrice = 0; // NEED UPDATE DELIVERY PRICE !!!
        const totalPrice = this.cartPrice + deliveryPrice;
        let referenceNumber = null;
        let errorMsg = '';

        const data = {
            TerminalNumber,
            Password,
            Track2,
            CardNumber,
            ExpDate_MMYY,
            CVV,
            TransactionSum: totalPrice.toFixed(2), //(format: 00.00)
            CurrencyType: 1, //(use 1 for NIS)
            J: 5,
            HolderID,
            CustomerName: this.userParams.name,
            PhoneNumber: this.userParams.phone,
            CustomerEmail: this.userParams.email,
            ObeligoAction: 0,
        }

        const headers = { "Content-Type": 'application/json; charset=utf-8' };
        const url = "https://pci.zcredit.co.il/ZCreditWS/api/Transaction/CommitFullTransaction";

        const resp = await axios.post(url, data, { headers })
        .then(response => {
            // console.log("CommitFullTransaction RESP::", response);
            referenceNumber = response.data.ReferenceNumber;
            errorMsg = response.data.ReturnMessage;
        })
        .catch(err => {
            // console.log("CommitFullTransaction ERROR::", err)
            errorMsg = 'ERROR';
        })
        
        // console.log("CommitFullTransaction ERROR_MSG::",errorMsg)
        return {referenceNumber, errorMsg};
    }
    
    @action
    getProductsParam = () => {
        let products = [];
        const { cartItemList } = this.getCart();
        let index=-1;

        // console.log('cartItemList', toJS(cartItemList));

        // cartItemList.forEach((cartItem, idx) => {
        for(let idx=0 ; idx<cartItemList.length ; idx++){
            if(cartItemList[idx].isReadyToOrder){
                const { productType } = cartItemList[idx].item.product;
                index++;

                // console.log("cartItemList[index].options:",cartItemList[index].options)
                // console.log("cartItemList[index]:",cartItemList[index])
                // console.log("cartItemList:",cartItemList)
                if(!productType || productType === 'pizza'){ // regular/pizza product
                    const hasProductOption = (cartItemList[idx] && cartItemList[idx].options) ? Object.keys(cartItemList[idx].options.productOption).length > 0 : false;
                    const hasToppings =  (cartItemList[idx] && cartItemList[idx].options) ? cartItemList[idx].options.toppings.length > 0 : false;
                    const hasBaseToppings =  (cartItemList[idx] && cartItemList[idx].item && cartItemList[idx].item.shopBaseToppings) ? cartItemList[idx].item.shopBaseToppings.length > 0 : false;
                    
                    products.push([`products[${index}][shopProductId]`, cartItemList[idx].item.id])
                    products.push([`products[${index}][unitType]`, cartItemList[idx].unitType ? cartItemList[idx].unitType.type : cartItemList[idx].item.defaultUnitType])
                    products.push([`products[${index}][amount]`, cartItemList[idx].quantity])
                    products.push([`products[${index}][comment]`, cartItemList[idx].comment])

                    if(hasProductOption){
                        products.push([`products[${index}][shopProductOptionId]`, cartItemList[idx].options.productOption.id]);
                    }
                    
                    if(hasToppings){
                        cartItemList[idx].options.toppings.forEach((topping, t_idx) => {
                            const q1 = topping.quarterList[0] ? '1' : '0';
                            const q2 = topping.quarterList[2] ? '1' : '0';
                            const q3 = topping.quarterList[1] ? '1' : '0';
                            const q4 = topping.quarterList[3] ? '1' : '0';
                            const positions = q1 + q2 + q3 + q4;

                            if(productType === 'pizza'){
                                products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positions])
                            }
                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id])
                        })
                    }

                    if(hasBaseToppings){
                        //find unSelected base toppings
                        const unSelectedBaseToppings = cartItemList[idx].item.shopBaseToppings?.filter(shopBaseTopping => 
                            cartItemList[idx].options.baseToppings?.every(nsBaseTopping => nsBaseTopping.id != shopBaseTopping.id))

                        unSelectedBaseToppings.forEach((baseTopping, bt_idx) => {
                            products.push([`products[${index}][shopBaseToppingIds][${bt_idx}]`, baseTopping.id])
                        })
                    }
                }
                else if(productType === 'pack'){  // pack product
                    const packIndex = index;
                    products.push([`products[${index}][isPack]`, 1])
                    products.push([`products[${index}][shopProductId]`, cartItemList[idx].item.id])
                    products.push([`products[${index}][unitType]`, cartItemList[idx].item.defaultUnitType])
                    products.push([`products[${index}][amount]`, cartItemList[idx].quantity])
                    products.push([`products[${index}][comment]`, ""])
                    
                    cartItemList[idx].steps.forEach(step => {
                        step.packItems.forEach(p_item => {
                            if(p_item.isReadyToOrder){
                                index++;
                                const hasProductOption = (p_item && p_item.options) ? Object.keys(p_item.options.productOption).length > 0 : false;
                                const hasToppings = (p_item && p_item.options) ? p_item.options.toppings.length > 0 : false;
                                const hasBaseToppings = (p_item && p_item.item && p_item.item.shopBaseToppings) ? p_item.item.shopBaseToppings.length > 0 : false;
                                
                                products.push([`products[${index}][relatedToPack]`, packIndex])
                                products.push([`products[${index}][shopProductId]`, p_item.item.id])
                                products.push([`products[${index}][unitType]`, p_item.item.defaultUnitType])
                                products.push([`products[${index}][amount]`, p_item.quantity])
                                products.push([`products[${index}][shopProductOptionIsPaid]`, step.optionsPaid])
                                products.push([`products[${index}][levelId]`, step.id])
                                
                                if(hasProductOption){
                                    products.push([`products[${index}][shopProductOptionId]`, p_item.options.productOption.id]);
                                }
                                
                                if(hasToppings){
                                    let quartersAmount = 0;
                                    let isPartialpaymentpaid = false // flag  - after partial payment topping! 
                                    const quartersFree = p_item.toppingsFree * 4; //max quarters free
                                    p_item.options.toppings.forEach((topping, t_idx) => {
                                        let toppingQuartersToPaid = 0; // for partial topping quarters payment
                                        let toppingIsPaid = null;
                                        const toppingQuartersAmount = topping.quarterList.filter(q => q).length;
                                        quartersAmount += toppingQuartersAmount;
                                        
                                        if(quartersAmount <= quartersFree){ //all topping quarters are free
                                            toppingIsPaid = 0
                                        }
                                        else{
                                            toppingQuartersToPaid = quartersAmount - quartersFree;
                                            const notpart = quartersAmount - toppingQuartersAmount === quartersFree;
                                            if(toppingQuartersToPaid > 0 && !isPartialpaymentpaid && !notpart){ //partial of topping quarters are free
                                                toppingIsPaid = 'part'
                                            }
                                            else{ //all of topping quarters are paid
                                                isPartialpaymentpaid = true;
                                                toppingIsPaid = 1;
                                            }
                                        }

                                        const q1 = topping.quarterList[0] ? '1' : '0';
                                        const q2 = topping.quarterList[2] ? '1' : '0';
                                        const q3 = topping.quarterList[1] ? '1' : '0';
                                        const q4 = topping.quarterList[3] ? '1' : '0';
                                        const positions = q1 + q2 + q3 + q4;

                                        if(toppingIsPaid !== 'part'){ //also for regular pack-item (with toppings)
                                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id])
                                            if(p_item.item.product.productType === 'pizza'){
                                                products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positions])
                                                products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, toppingIsPaid /*topping.price === 0 ? 0 : 1*/])
                                            }
                                            else{
                                                const isToppingPaid = t_idx < p_item.toppingsFree ? 0 : 1;
                                                products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, isToppingPaid])
                                            }
                                        }
                                        else{
                                            isPartialpaymentpaid = true;
                                            let positionsPaid = '';
                                            let positionsFree = '';
                                            let count = 0;
                                            for(let i=0; i<positions.length ; i++){
                                                if(positions[i] === '1'){
                                                    count++;
                                                    if(toppingQuartersToPaid >= count){
                                                        positionsPaid += '1';
                                                        positionsFree += '0'
                                                    }
                                                    else{
                                                        positionsPaid += '0';
                                                        positionsFree += '1'
                                                    }
                                                }
                                                else{
                                                    positionsPaid += '0';
                                                    positionsFree += '0'
                                                }
                                            }
                                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id])
                                            products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positionsPaid])
                                            products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, 1])

                                            products.push([`products[${index}][shopToppingIds][${t_idx+0.5}]`, topping.id])
                                            products.push([`products[${index}][shopToppingPositions][${t_idx+0.5}]`, positionsFree])
                                            products.push([`products[${index}][shopToppingIsPaid][${t_idx+0.5}]`, 0])
                                            
                                        }

                                    })
                                }

                                if(hasBaseToppings){
                                    //find unSelected base toppings
                                    const unSelectedBaseToppings = p_item.item.shopBaseToppings?.filter(shopBaseTopping => 
                                        p_item.options.baseToppings?.every(nsBaseTopping => nsBaseTopping.id != shopBaseTopping.id))


                                    unSelectedBaseToppings.forEach((baseTopping, bt_idx) => {
                                        products.push([`products[${index}][shopBaseToppingIds][${bt_idx}]`, baseTopping.id])
                                    })
                                }
                            }
                        })
                    })
                }
            }
        }

        // console.log('products', toJS(products));

        return products;
    }

    getTimeStamp = () => {
        return Math.floor(Date.now() / 1000);
    }
    
    getFormatedTimeHHMM = () => { //return formated string->   HH:MM 
        const date = new Date();

        const hh = date.getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        
        const mm = date.getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        
        return `${hh}:${mm}`;
    }
order
    @action
    makeOrderResp = async (referenceNumber, token, creditData) => {
        this.hasPostCoupon = false;
        const { directPayment } = this.shop; 
        const products = this.getProductsParam();
        const form = new FormData();
        const coupon = this.getCoupon();

        //add products to form
        products.forEach(product => {
            form.append(product[0], product[1]);
        })

        form.append('source', "site");
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('onesignalId', ''); //CHECK THIS!
        form.append('orderType', this.orderData.isDelivery ? 'delivery' : 'pickup');
        form.append('paymentType', this.shop.isBusiness ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash')));
        this.cardNumber && this.otherPaymentType && form.append('paymentCard', this.cardNumber);
        form.append('coupon', coupon ? coupon.code : ''); //CHECK THIS!
        this.orderData.branch && form.append("branchId", this.getBranchId());

        // if(this.orderData.isCredit){
        //     form.append('creditTxHolderId', creditData.id);
        //     form.append('creditTxExpDate', creditData.expDate);
        //     form.append('creditTxConfirm', token);
        //     form.append('creditTxCardNo', creditData.cc_number.slice(-4));
        //     if(directPayment === 1){
        //         form.append('creditTxCVV', creditData.cvv);
        //     }
        //     else{
        //         form.append('creditTxIndex', referenceNumber);
        //     }
        // }

        if(this.orderData.isCredit){
            // console.log("uniqueId:", this.creditUniqueId)
            form.append('UniqueID', this.creditUniqueId);
        }

        if(this.orderData.isDelivery){
            const { deliveryData } = this.orderData;
            form.append('deliveryComment', deliveryData.deliveryGuyComment);
            form.append('deliveryDate', this.shop.withoutFuture_delivery ? this.getTimeStamp() : deliveryData.date);
            form.append('deliveryFrom', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.from);
            form.append('deliveryTo', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.to);
            form.append('comment', deliveryData.orderComment);
            form.append('deliveryCost', this.deliveryCost);
            form.append('distance', this.orderData.distance);
            form.append('deliveryLat', this.deliveryLatLng ? this.deliveryLatLng.lat : null);
            form.append('deliveryLon', this.deliveryLatLng ? this.deliveryLatLng.lng : null);
        }
        else{ /// pickup
            const { pickupData } = this.orderData;
            form.append('deliveryDate', this.shop.withoutFuture_pickup ? this.getTimeStamp() : pickupData.date);
            form.append('deliveryFrom', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.from);
            form.append('deliveryTo', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.to);
            form.append('deliveryCost', null);
            form.append('comment', pickupData.orderComment);
            form.append('distance', 0);
        }

        const url = this.getApi('make-order');
        const res = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Bearer': this.userToken
            },
        });
        
        if(res.data.error != null) {
            // console.log("makeOrder ERROR::", res.data);
        } else {
            // console.log("makeOrder RESP::", res);
            if(res?.data?.promotionCoupon?.code){
                this.hasPostCoupon = true;
                this.postCouponData = res?.data?.promotionCoupon;
            }
            this.clearCoupon();
        }
        return res;
    }

    @action
    setStepNum = (stepNum) => {
        this.stepNum = stepNum;
    }

    @action
    getCartPackItemPrice = (cartPackItem) => {
        let totalPrice = 0;
        totalPrice += this.getItemPrice(cartPackItem);
        cartPackItem.steps.forEach(step => {
            step.packItems.forEach(packItem => {
                if(packItem.isReadyToOrder){
                    totalPrice += this.getItemPrice(packItem, true);
                }
            })
        })
        return totalPrice;
    }

    @action
    calcTotalCartPrice = () => {
        let totalPrice = 0;
        
        //checks if cart is empty
        if(!this.getCart().cartItemList.length){
            this.deliveryCost = 0;
            this.cartPrice = 0;
            return;
        }

        this.getCart().cartItemList.forEach(cartItem => {
            if(cartItem.isReadyToOrder){
                if(cartItem.item.product.productType === "pack"){  //calculate cart-pack-item price
                   totalPrice += this.getCartPackItemPrice(cartItem);
                }
                else{
                    totalPrice += this.getItemPrice(cartItem);
                }
            }
        })

        //calculate coupon discount
        const coupon = this.getCoupon();
        if(coupon && Number.isInteger(coupon.discount)){
            totalPrice *= ((100-coupon.discount)/100);
        }

        //calculate delivery cost
        totalPrice += this.deliveryCost;

        this.cartPrice = totalPrice;

        // console.log('totalPrice:', totalPrice);
    }

    @action
    editCartItemUpdating = (selectedProductOption, selectedToppings, selectedBaseToppings) => { 
        //update the "options" of 'cartItemUpdating' variable
        if(this.isPackItemUpdating){ //update cart item of 'pack' type
            //console.log(toJS(this.packItemUpdating));
            this.packItemUpdating.options.productOption = selectedProductOption;
            this.packItemUpdating.options.toppings = selectedToppings;
            this.packItemUpdating.options.baseToppings = selectedBaseToppings;
        }
        else{
            //console.log(toJS(this.cartItemUpdating));
            this.cartItemUpdating.options.productOption = selectedProductOption;
            this.cartItemUpdating.options.toppings = selectedToppings;
            this.cartItemUpdating.options.baseToppings = selectedBaseToppings;
        }
    }
    
    @action
    closeEditCartPackForm = () => {
        this.isEditCartPackForm = false;
    }

    @action
    openPackItemForm = (packItem) => { //opening form for item in pack
        this.isPackItemUpdating = true;
        this.packItemUpdating = packItem;
    }

    @action
    closePackItemForm = () => { //closing form for item in pack
        this.isPackItemUpdating = false;
        this.packItemUpdating = null;
    }

    @action
    openCartItemForm = (cartItem, isCartPackItem = false, isOpenForEdit = false) => {
        this.isOpenForEdit = isOpenForEdit;

        if(isCartPackItem) {
            this.isEditCartPackForm = true 
            this.setStepNum(1);
        };
        this.isCartItemUpdating = true;
        this.cartItemUpdating = cartItem;
    }

    @action
    closeCartItemForm = () => {
        this.isCartItemUpdating = false;
        this.cartItemUpdating = null;
    }

    @action
    getItemPrice = (item, isPackItem = false) => {
        let price = 0;
        const hasProductOptions = (item.item && item.item.shopProductOptions) ? item.item.shopProductOptions.length > 0 : false;
        const hasToppings = (item.item && item.item.shopToppings) ? item.item.shopToppings.length > 0 : false;
        const isEditableItem = hasProductOptions || hasToppings;
        const isPizza = item.item.product.productType === "pizza";
        // const isPack = item.item.product.productType === "pack";

        if(!isPackItem){
            price += item.unitType ? item.unitType.price : item.item.unitTypes[0].price;
            price *= item.quantity;
        }

        if(isPizza && isEditableItem){ //Checks if the cart-item is editable and its pizza.
            if(!isPackItem || item.optionsPaid === 1){
                if(hasProductOptions) price += item.options.productOption.price; //add price of product option
            }
            if(hasToppings){
                if(item.toppingsFree && isPizza){ //calc firsts free toppings quarters price (for pack item - pizza)
                    const quartersFree = item.toppingsFree * 4;
                    let quartersCount = 0; // number of toppings quarters
            
                    //calculate quarters toppings
                    item.options.toppings.forEach(t => {
                        t.quarterList.forEach(q => {
                            price += (quartersCount < quartersFree  && q)
                                        ?
                                            0
                                        :
                                            q
                                            ?
                                                t.price/4
                                            : 
                                                0;

                            quartersCount += q ? 1 : 0;
                        })
                    })
                }
                else{
                    item.options.toppings.forEach(topping => {
                        const fullPriceTopping = topping.price;
                        topping.quarterList.forEach(quarter => {
                            if(quarter){
                                price += (fullPriceTopping / 4)
                            }
                        })
                    })
                }
            }
        }
        else if(isEditableItem){ //Checks if the cart-item is editable (and NOT a pizza item)
            //calculate cost of ingredients
            if(hasProductOptions){ 
                price += item.optionsPaid === 0 ? 0 : item.options.productOption.price; //add price of product option
            } 
            if(hasToppings){
                if(item.toppingsFree){ //calc firsts (firsts selected!!) free toppings price (for pack item - regular)
                    item.options.toppings.forEach((topping, t_idx) => {
                        price += t_idx < item.toppingsFree ? 0 : topping.price;
                    })
                }
                else{ // add price of all toppings
                    item.options.toppings.forEach(topping => { 
                        price += topping.price;
                    })
                }
            }
        }

        return price;
    }

    @action
    removeCartItem = (index, removeMult) => {
        let newCartItemList = null;
        let canReduceQuantity = false;
        

        if(this.getCart().cartItemList){
            //checks if item amount is unequal to 1
            this.getCart().cartItemList.forEach((cartItem, idx) => {
                if(index === idx){
                    const unitTypeMult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                    if(cartItem.quantity !== unitTypeMult && cartItem.quantity-unitTypeMult > 0){
                        canReduceQuantity = true;
                    }
                }
            });

            if(removeMult){
                newCartItemList = [];
                if(canReduceQuantity){
                    this.getCart().cartItemList.forEach((cartItem, idx) => {
                        if(index === idx){
                            const unitTypeMult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                            cartItem.quantity = this.fixFloatingPoint(cartItem.quantity-unitTypeMult);
                        }
                        newCartItemList.push(cartItem);
                    });
                }
                else{
                    newCartItemList = this.getCart().cartItemList.filter((_, idx) => {
                        const isChosenItem = index === idx;
                        return !isChosenItem;
                    });
                }
            }
            else{
                newCartItemList = this.getCart().cartItemList.filter((_, idx) => {
                    const isChosenItem = index === idx;
                    return !isChosenItem;
                });
            }
        }
        this.getCart().cartItemList = [...newCartItemList];

        this.calcTotalCartPrice();
    }

    fixFloatingPoint = val => Number.parseFloat(val.toFixed(13))

    @action
    addToCart = (item, unitType) => {
        let itemInCart = false;
        const hasProductOptions = (item && item.shopProductOptions) ? item.shopProductOptions.length : false;
        const hasToppings = (item && item.shopToppings) ? item.shopToppings.length > 0 : false;
        const hasBaseToppings = (item && item.shopBaseToppings) ? item.shopBaseToppings.length > 0 : false;
        const isEditableItem = hasProductOptions || hasToppings || hasBaseToppings;  
        const isCartPackItem = (item && item.product && item.product.levels) ? item.product.levels.length : null;

        if(this.getCart().cartItemList.length){ // Checks if there is the same item in the cart
            itemInCart = this.getCart().cartItemList.some(cartItem => cartItem.item.id === item.id);
        }
        if(!isEditableItem && !isCartPackItem && itemInCart){ //Checks if item is in cart and is not editable and not a pack
            this.getCart().cartItemList.forEach(cartItem => {
                const mult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                if(cartItem.item.id === item.id){
                    cartItem.quantity = this.fixFloatingPoint(cartItem.quantity + mult);
                }
            })
        }
        else if(isCartPackItem){
            let steps = [];
            let stepNum = 1;
            const { levels } = item.product;
    
            levels.forEach((level) => {
                const { productsAmount, optionsPaid, toppingsAddPaid } = level;
                const { products, ...otherLevelProps} = level;
                for(let i=1; i<=productsAmount ; i++){
                    let packItems = [];
                    products.forEach(product => {
                        const isEditablePackItem = product.shopProductOptions.length || product.shopToppings.length || product.shopBaseToppings?.length;
                        let productOption = {};
                        if(product.shopProductOptions.length){
                            const defaultOption = product.shopProductOptions.filter(opt => opt.price === 0);
                            productOption = {
                                name: defaultOption.length ? defaultOption[0].productOption.name : product.shopProductOptions[0].productOption.name, 
                                price: defaultOption.length ? defaultOption[0].price : product.shopProductOptions[0].price,
                                id: defaultOption.length ? defaultOption[0].id : product.shopProductOptions[0].id,
                            };
                        }

                        const options = { 
                            productOption,
                            toppings: [],
                            baseToppings: product.shopBaseToppings?.length > 0 ? product.shopBaseToppings : [],
                        };
                        const newPackItem = {
                            item: product, 
                            quantity: 1,
                            id: uuidv4(),
                            isReadyToOrder: false,
                            unitType,
                            comment: ''
                        };
                        if(isEditablePackItem){
                            newPackItem.options = options;
                            newPackItem.optionsPaid = optionsPaid;
                            newPackItem.toppingsAddPaid = toppingsAddPaid;
                            newPackItem.toppingsFree = level.toppingsFree;
                            
                        }
                        packItems.push(newPackItem)
                    })

                    steps.push({...otherLevelProps, stepNum: stepNum++, itemNum: i, packItems});
                }
            })

            const newCartItem = {
                item, 
                quantity: 1,
                id: uuidv4(),
                isReadyToOrder: true,
                steps,
                unitType,
                comment: ''
            };
            this.getCart().cartItemList.push(newCartItem);
            this.openCartItemForm(this.getCart().cartItemList[this.getCart().cartItemList.length-1]); //open form editor after submited!
        }
        else if(!isEditableItem){ //if item is NOT in cart and is not editable
            const newCartItem = {
                item, 
                quantity: unitType.multiplier,
                id: uuidv4(),
                isReadyToOrder: true,
                unitType,
                comment: ''
            };
            this.getCart().cartItemList.push(newCartItem);
        }
        else{
            let productOption = {};
            if(hasProductOptions){
                const defaultOption = item.shopProductOptions.filter(opt => opt.price === 0);
                productOption = {
                    name: defaultOption.length ? defaultOption[0].productOption.name : item.shopProductOptions[0].productOption.name, 
                    price: defaultOption.length ? defaultOption[0].price : item.shopProductOptions[0].price,
                    id: defaultOption.length ? defaultOption[0].id : item.shopProductOptions[0].id
                };
            }
            const options = { 
                productOption,
                toppings: [],
                baseToppings: item.shopBaseToppings?.length > 0 ? item.shopBaseToppings : [],

            };
            const newCartItem = {
                item, 
                options,
                quantity: 1,
                id: uuidv4(),
                isReadyToOrder: true,
                unitType,
                comment: ''
            };
            this.getCart().cartItemList.push(newCartItem);
            this.openCartItemForm(this.getCart().cartItemList[this.getCart().cartItemList.length-1]); //edit after submited!
        }
        this.calcTotalCartPrice();
    };

    @action
    setMaxSideMenuIdx(idx) {
        if (idx > this.MaxSideMenuIdx) {
            this.MaxSideMenuIdx = idx;
        }
    }

    @action
    resetState = () => {
        this.init = false;
        this.isMaintenance = my_env.mStatus === "true"? true : false;
        this.MaxSideMenuIdx = 0;
        this.ActiveSideMenuIdx = 0;
        this.itemList = null;
        this.bgCode = this.bgCodeDef;
        this.isProductsLoading = false;
    }

    @action
    setInit = async () => {
        this.resetState();
        // this.getApiResp();
        await this.getApiResp_SetShop();
        this.setData();

        this.init = true;
    }

    @action
    async setData(){
        ////////////////
        !this.shopsData && this.setCart(); //added for single store ******
        ///////////////
        this.setShopData();
        this.setShop();
        await this.setShopLatLng()
        this.setCategories();
        this.getItemList(0, true);

        this.setBgCode();
        this.setBodyBg();
        this.setMainColor();
    }

    @action
    generateCsrf = () => {
        this.csrf = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    getUrl = (url='') => {
        return my_env.url + (url === '' ? '' : '/' + url);
    }
    getApi = (url='') => {
        return my_env.url + '/api' + (url === '' ? '' : '/' + url);
    }

    isShopData(){
        return this.shopData !== null;
    }
    isShop(){
        return this.shop !== null;
    }

    @action
    getShopData(){
        return this.shopData? this.shopData : null;
    }

    @action
    getAbout(){
        // return this.isShopData()? (this.shopData['about'] ? this.shopData['about'] : null) : null;
        return this.isShopData()? (this.shop['about'] ? this.shop['about'] : null) : null;
    }

    @action
    getTerms(){
        return this.isShopData()? (this.shopData['rules'] ? this.shopData['rules'] : null) : null;
    }

    @action
    getPPolicy(){
        return this.isShopData()? (this.shopData['privacy_policy'] ? this.shopData['privacy_policy'] : null) : null;
    }

    @action
    getRetPolicy(){
        return this.isShopData()? (this.shopData['return_policy'] ? this.shopData['return_policy'] : null) : null;
    }

    @action
    getContacts(){
        return 'contacts';
    }
    getShopName() {
        return this.isShop()? (this.shop['name'] ? this.shop['name'] : null) : null;
    }
    getShopAddress() {
        return this.isShop()? (this.shop['address'] ? this.shop['address'] : null) : null;
    }
    getShopContactEmail() {
        return 'sales@bigapps.co.il';
    }
    getShopPhone() {
        return this.isShop()? (this.shop['phone'] ? this.shop['phone'] : null) : null;
    }
    getShopPhone2() {
        return this.isShop()? (this.shop['phone2'] ? this.shop['phone2'] : null) : null;
    }
    getShopWorkTimes() {
        return this.isShop()? (this.shop['workingTimes'] ? this.shop['workingTimes'] : null) : null;
    }
    @action
    getHistory(){
        return this.history;
    }
    @action
    clearHistory(){
        this.history = null;
    }

    @action
    setBgCode() {
        if(this.isShop()) {
            this.bgCode = this.shop.backgroundCodename ? this.shop.backgroundCodename : this.bgCodeDef;
        }
    }

    @action
    getBgCode() {
        this.setBgCode();
        return this.bgCode;
    }

    @action
    setBodyBg(id='') {
        const bg = id === '' ? this.getBgCode() : id;
        const fileBg = 'url(' + my_env.url + "/upload/bg_admin/" + bg + ".jpg)"; 
        this.fileBg = fileBg;
        return fileBg;
    }

    @action
    setMainColor() {
        if(this.isShop()) {
            this.mainColor = this.shop.mainColor ? '#'+this.shop.mainColor : this.mainColorDef;
        }
        
    }

    @action
    getMainColor() {
        // this.setMainColor();
        return this.mainColor;
    }

    getShopDataItem(item){
        return this.shopData[item] ? this.shopData[item] : null;
    }

    @action
    setShopData(){
        if(this.apiResp !== null){
            // this.shopData = this.apiResp.data.data;
            this.shopData = this.apiResp;
            // console.log("setShopData success.");
        }
    }

    @action
    setShop(){
        if(this.shopData !== null){
            this.shop = this.shopData.shop;
            // console.log("setShop success.");
        }
    }

    @action
    async setShopLatLng(){
        if(this.shop !== null){
            if(this.shop.shopLat && this.shop.shopLon){
                this.shopLatLng = { shopLat: this.shop.shopLat, shopLng: this.shop.shopLon };
            }
            else if(this.shop.address){
                await Geocode.fromAddress(this.shop.address).then(
                    (response) => {
                      const { lat, lng } = response.results[0].geometry.location;
                      this.shopLatLng = { shopLat: lat, shopLng: lng };
                    },
                    (error) => {
                      console.error('myShopApi > setShopLatLng :: error:', error);
                    }
                  );
            }
        }
    }

    @action
    getShopLatLng = () => {
        return this.shopLatLng;
    }

    @action
    getDeliveryLatLng = () => {
        return this.deliveryLatLng;
    }

    @action
    setDeliveryLatLng = (coords) => {
        this.deliveryLatLng = coords;
    }

    @action
    setCategories(){
        if(this.shop !== null){
            // this.categories = this.shop.categories.sort((a, b) => a.codename > b.codename? 1: -1);
            this.categories = this.shop.categories;
            // console.log("setCategories success.");
        }
    }
    @action
    getCategories(){
        return this.categories;
    }

    @action
    getCatCodeByActiveIdx(_idx){
        const code = this.categories ? this.categories[_idx].codename : null;
        // console.log("getCatCodeByActiveIdx idx:", [_idx, " code:", code]);
        return  code;
    }

    @action
    getSearchProducts = async (productName, parentId) => {
        const url = this.getApi('search-products');
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('productName', productName);
        parentId && form.append('categoryId', parentId);

        this.isSearchingProducts = true;
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        // console.log('resp:', resp)
        this.isSearchingProducts = false;
        if(resp.data.errorCode === '0'){
            return resp.data.data.products
        }
        else return null;
    }

    @action
    initItemList = () => {
        this.itemList = [];
    }

    @action
    getItemList = async (_idx, is_new) => {
        if (this.isProductsLoading) return;

        this.isProductsLoading = true;

        if(is_new) {
            this.itemList = [];
        }

        const cat = this.getCatCodeByActiveIdx(_idx);
        if(!cat) {
            return null;
        }
        const url = this.getApi('get-products');
        var form = new FormData();
        // form.append('shopId', my_env.shopId);
        form.append('shopId', this.shopId);
        form.append('categoryCodename', cat);
        form.append('pageSize', 10);
        let categoryPage = is_new ? 1 : this.categoryPage + 1;
        form.append('page', categoryPage);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        // console.log("getItemList resp:", resp);

        if(resp.data.error != null) {
            this.isMaintenance = true;
            this.categoryPage = categoryPage;
            this.categoryPageCount = 0;
        } else {
            this.itemListResp = resp.data.data;
            this.categoryPage = categoryPage;
            this.categoryPageCount = typeof this.itemListResp.pagesCount != 'undefined' ? this.itemListResp.pagesCount : 0;
            if(is_new) this.itemList = this.itemListResp.categoryProducts[cat];
            else this.itemList = this.itemList.concat(this.itemListResp.categoryProducts[cat]);
        }

        this.isProductsLoading = false;

        this.selectedCategoryIdx = _idx;
    }

    @action
    getProductsAssociated = async (shopProductsAssociatedIds) => {
        const url = this.getApi('get-products');
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('categoryCodename', '');

        //add products to form
        shopProductsAssociatedIds.forEach((product,p_idx) => {
            form.append(`cartProductIds[${p_idx}]`, product);
        })
        // form.append('cartProductIds', JSON.parse(shopProductsAssociatedIds));

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        // console.log("getProductsAssociated => getItemList resp:", resp);
        return resp?.data?.data
    }

    @action
    getApiResp_SetShop = async () => {
        const url = this.getApi('set-shop');
        var form = new FormData();
        // form.append('shopId', my_env.shopId);
        form.append('shopId', this.shopId);
        form.append('source', 'site');
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if(resp.data.error != null) {
            this.isMaintenance = true;
            // console.log("SET_SHOP ERROR::", resp.data.error);
        } else {
            // console.log("SET_SHOP RESP::",resp)
            this.apiResp = resp.data.data;
            this.shopId === 1 && !this.shopsData && this.setData();
        }
    }

    @action
    getValidateCode = async (shopId, code) => { //for business store!
        const url = this.getApi('validate-code');
        var form = new FormData();

        form.append('shopId', shopId);
        form.append('code', code);
        
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if(resp.data.error != null) {
            // console.log("validate-code ERROR::", resp.data.error);
        } else {
            // console.log("validate-code RESP::",resp)
        }

        return resp.data;
    }

    ////NOT REMOVE!
    // @action
    // getApiResp = async () => {
    //     console.log("IN getApiResp !!!")
    //     var form = new FormData();
    //     form.append('shopId', my_env.shopId);

    //     const url = this.getApi('set-shop');
    //     // console.log("url:", url);
    //     await axios({
    //         method: 'post',
    //         url,
    //         data: form,
    //         headers: {
    //             'content-type': `multipart/form-data; boundary=${form._boundary}`,
    //         },
    //     }).then((response) => {
    //         // console.log(response);
    //         this.apiResp = response;
    //         this.setData();
    //         return response;
    //     }, (error) => {
    //         this.isMaintenance = true;
    //         if(my_env.debug){
    //             console.log("error:", error);
    //         }
    //     });
    //     // console.log("shop:", this.apiResp);
    //     return this.apiResp;
    // }

    @action
    setUserParams = (param, value) => {
        this.userParams[param] = value;
    }
   
    @action
    setCreditParams = (param, value) => {
        this.creditParams[param] = value;
    }

    @action
    registerUser = async () => {
        const url = this.getApi('upsert-user');
        var form = new FormData();
        form.append('shopId', this.shopId);

        for (let param in this.userParams) {
            form.append(param, this.userParams[param]);
        }
        form.append('userId', this.userId);

        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        if(resp.data.error != null) {
            // console.log("registerUser ERROR::", resp.data);
        } else {
            // console.log("registerUser RESP::", resp.data);
        }
        return resp.data;
    }

    @action
    verifyPhone = async (code) => {
        const url = this.getApi('verify-phone');
        var form = new FormData();

        form.append('phone', this.userParams.phone);
        form.append('code', code);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        if(resp.data.data === null) {
            // console.log("verifyPhone ERROR::", resp.data);
            if(resp.data.errorMessage === 'wrong code'){
                this.setWrongSMSCode(true);
            }
            return true;
        } 
        else {
            // console.log("verifyPhone RESP::", resp.data);
            this.userToken = resp.data.data.token;
            this.userId = resp.data.data.id;
            this.shopId = resp.data.data.shopId;
            this.setWrongSMSCode(false);
            return false;
        }
    }

    getShopsResp = async () => {
        const url = this.getApi('get-shops');
       
        const resp = await axios({
            method: 'get',
            url,
        });

        if(resp.data.error != null) {
            // console.log("get-shops ERROR::", resp.data);
        } 
        else {
            if(resp.data.data.shops && !resp.data.data.shops.length){ //one shop!
                this.shopsData = null;
                this.shopId = 1;
            }
            else{//all shops
                // console.log("resp.data.data:",resp.data.data)
                this.shopsData = resp.data.data
            }
        }
    }

    checkCouponResp = async (couponCode) => {
        const url = this.getApi('check-coupon');
        var form = new FormData();
        form.append('coupon', couponCode);
        form.append('shopId', this.shopId);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if(resp.data.data === null) {
            // console.log("check-coupon ERROR::", resp.data);
        }
        else {
            // console.log("check-coupon RESP::", resp.data);
            this.setCoupon(couponCode, resp.data.data.discount)
        }

        return resp.data;
    }


    @action
    getOrderHistoryResp = async (isFirstPage = true) => {
        const url = this.getApi('get-order-history');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('pageSize', 10);

        const page = isFirstPage ? this.historyPage : this.historyPage + 1;
        form.append('page', page);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });
        
        if(resp.data.error != null) {
            // console.log("getOrderHistoryResp ERROR::", resp.data);
        } else {
            // console.log("getOrderHistoryResp RESP::", resp.data);
            if(resp?.data?.orders?.length){
                this.historyPage = page;
            }
        }
        return resp.data;
    }
    
    @action
    getOrderHistoryTimeResp = async () => {
        const url = this.getApi('order-history-times');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        if(resp.data.errorCode === '0' && resp.data.data && resp.data.data.times) {
            // console.log("getOrderHistoryTimeResp RESP::", resp.data);
            return resp.data.data.times;
        } else {
            // console.log("getOrderHistoryTimeResp ERROR::", resp.data);
            return null;
        }
        
    }

    @action
    callback(data, error){
        // consume data
        if (error) {
            console.error(error);
            return;
        }
        // console.log("data:", data);
        return data;
    };

    @action
    async request(retries, callback, params) {
        // console.log('params:',params)
        const res = await axios({
            ...params
        })
        .then(async response => {
            // request successful
            if(response.data.errorCode === '0') {
                // server done, deliver data to script to consume
                return callback(response);
            }
            else {
                // server not done yet
                // retry, if any retries left
                if (retries > 0) {
                    await this.request(--retries, callback);
                }
                else {
                    // no retries left, calling callback with error
                    return callback([], "out of retries");
                    
                }
            }
        }).catch(async error => {
            // ajax error occurred
            // would be better to not retry on 404, 500 and other unrecoverable HTTP errors
            // retry, if any retries left
            if (retries > 0) {
                await this.request(--retries, callback);
            }
            else {
                // no retries left, calling callback with error
                return callback([], error);
            }
        });

        return res;
    }

    @action
    makePreorderResp = async () => {
        const url = this.getApi('make-preorder');
        const form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('sum', this.cartPrice);

        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const params = {
            method: 'post',
            url,
            data: form,
            headers
        }
        const resp = await this.request(10, this.callback, params);

        if(!resp){
            // console.log('makePreorderResp ERROR');
            this.setCreditUniqueId('')
        }
        else{
            try{
                // console.log('makePreorderResp RESP', resp.data.data.UniqueId);
                resp.data.errorCode === '0' && this.setCreditUniqueId(resp.data.data.UniqueId);
            }catch(err){
                this.setCreditUniqueId('');
                // console.log('makePreorderResp ERROR', err);
            }
        }
    }

    @action
    checkPreorderResp = async () => {
        const url = this.getApi('check-preorder');
        const form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('sum', this.cartPrice);
        form.append('UniqueID', this.creditUniqueId);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }
      
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        // console.log("checkPreOrder only resp:::", resp);
        return resp;

    }

    @action
    createSessionResp = async () => {
        this.setSessionUrl(''); 

        const url = 'https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession'
        // const key = process.env.REACT_APP_CREATE_SESSION_API_KEY;
        const key = this.shop.paymentApiKey;

        this.calcTotalCartPrice();

        const cartItems = [{
            "Amount": this.cartPrice ? this.cartPrice.toString() : '0',
            "Currency": 'ILS',
            // "Quantity": this.getCart().cartItemList.length,
            "Quantity": 1,
            "Name": 'הזמנה',
            //"Description": "My Item description , comes below the name" , 
            //"Image": "https://www.z-credit.com/site/wp-content/themes/z-credit/img/decisions/decision2.png" ,
            "IsTaxFree":  "false",
        }]

        const data =  {
            "Key": key,
            "Local": "He",
            "UniqueId": this.creditUniqueId,
            "SuccessUrl": '',
            // "SuccessUrl": `${window.location.href}`,
            "CancelUrl": "",
            "CallbackUrl": `${this.getApi('callback-preorder-c575a054e658c0c1b9b7d42856bf7f5b')}`,
            "PaymentType": /*this.shop.directPayment ? "validate" : */"authorize",
            "CreateInvoice": "false",
            "AdditionalText": "",
            "ShowCart": "false",
            "Installments": 0,
            "Customer": {
                "Email": this.userParams.email,
                "Name": this.userParams.name ,
                "PhoneNumber":  this.userParams.phone,
                "Attributes": {
                    "HolderId":  'required',
                    "PhoneNumber":  'required' ,
                    "Name":  'optional' ,
                    "Email":  'optional'
                }
            },
        "CartItems": cartItems
        }

        //update payments number
        if(this.shop.maxPayments > 1){
            data["Installments"] = {
                Type: "regular" , 
                MinQuantity: "1",
                MaxQuantity: this.shop.maxPayments
            };
        }

        const headers = {
            'content-type': 'application/json; charset=utf-8'
        }

        let resp = false;
        try {
            resp = await axios({
                method: 'post',
                url,
                data,
                headers
            });
        } catch(error) {
            //console.log(error)
        }

        if(!resp || !resp.data) {
            this.setSessionUrl('ERROR'); 
            console.log(resp, 'err_1');
        } else {
            if(resp.data.HasError) {
                // console.log("createSessionResp ERROR::", resp.data);
                this.setSessionUrl('ERROR');
                console.log(resp, 'err_2');
            } else {
                // console.log("createSessionResp RESP::", resp.data);
                this.setSessionUrl(resp.data.Data.SessionUrl);
            }
        }

    }

    @action
    getDeliveryPriceResp = async () => {
        const url = this.getApi('get-delivery-price');
        const form = new FormData();
        form.append('shopId', this.shopId);
        const { lat, lng } = this.getDeliveryLatLng();
        form.append('deliveryLat', lat);
        form.append('deliveryLon',lng);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }
      
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        // console.log("getDeliveryPriceResp resp:::", resp);
        return resp;
    }
}

export default MyShopApiStore